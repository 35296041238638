const cardDemo: any = {
  title: {
    fr: "L'outil de Networking des temps modernes",
    ar: "بطاقة عملك من الجيل الجديد",
    en: "The Modern Networking Tool",
  },
  subtitle: {
    fr: "Popcard vous permet de partager vos coordonnées professionnelles et bien plus en un simple toucher sur un smartphone.",
    ar: "بوب كارد تمكنك من مشاركة بيانات الاتصال الخاصة بك وأكثر من ذلك بلمسة واحدة على الهاتف الذكي.",
    en: "Popcard allows you to share your professional contact details and much more with a simple tap on a smartphone.",
  },
};

export default cardDemo;

"use client";
import React from "react";
import { motion } from "framer-motion";
import Image from "next/image";

import AnfaplaceSrc from "@/assets/companies/anfaplace.svg";
import Ansamble from "@/assets/companies/Ansamble.png";
import BcpSrc from "@/assets/companies/bcp.svg";
import Casanet from "@/assets/companies/Casanet.png";
import Deloitte from "@/assets/companies/Deloitte.png";
import EY from "@/assets/companies/EY.png";
import Finactu from "@/assets/companies/Finactu.png";
import MADAEF from "@/assets/companies/MADAEF.png";
import MarganeSrc from "@/assets/companies/marjane.svg";
import Nareva from "@/assets/companies/Nareva.png";
import PandG from "@/assets/companies/P&G.png";
import pwc from "@/assets/companies/pwc.png";
import RenaultSrc from "@/assets/companies/renault.svg";
import Technopark from "@/assets/companies/Technopark.png";
import Varitas from "@/assets/companies/veritas.svg";
import TotalEnergies from "@/assets/companies/TotalEnergies.png";
import hero from "@/translation/home/heroTranslation";
// Define the array of slides with numbers
const slides = [
  { number: 1 },
  { number: 2 },
  { number: 3 },
  { number: 4 },
  { number: 5 },
  { number: 6 },
  { number: 7 },
  { number: 8 },
  { number: 9 },
  // { number: 10 },
  // { number: 11 },
];

const COMPANIES = [
  {
    src: MarganeSrc,
  },
  {
    src: AnfaplaceSrc,
  },
  {
    src: RenaultSrc,
  },
  {
    src: BcpSrc,
  },
  {
    src: Varitas,
  },
  {
    src: Ansamble,
  },
  {
    src: Casanet,
  },
  {
    src: Deloitte,
  },
  {
    src: EY,
  },
  {
    src: Finactu,
  },
  {
    src: MADAEF,
  },
  {
    src: Nareva,
  },
  {
    src: PandG,
  },
  {
    src: pwc,
  },
  {
    src: Technopark,
  },
  {
    src: TotalEnergies,
  },
];

const CompaniesSlider = ({ lang }: { lang: string }) => {
  // Duplicate the slides array to ensure seamless looping
  const duplicatedSlides = [...COMPANIES, ...COMPANIES, ...COMPANIES];

  return (
    <div className="relative w-full overflow-hidden mt-8">
      <div className="mb-8">
        <p className="text-light-gray text-md sm:text-lg text-center">
          {hero.trusted[lang]}
        </p>
      </div>
      <motion.div
        className="flex mt-4 gap-2"
        animate={{
          x: lang === "fr" || lang === "en" ? ["-100%", "0%"] : ["0%", "100%"],
          transition: {
            ease: "linear",
            duration: 30,
            repeat: Infinity,
            repeatType: "reverse",
          },
        }}
      >
        {/* Render duplicated slides */}
        {duplicatedSlides.map((slide, index) => (
          <div
            key={index}
            className="flex-shrink-0 bg-neutral-800 py-4 rounded"
            style={{ width: `${100 / slides.length}%`, minWidth: "100px" }}
          >
            <div className="flex flex-col items-center justify-center h-full text-6xl">
              <Image
                src={slide.src}
                alt="company"
                className="object-contain max-w-[80%]"
              />
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default CompaniesSlider;

const newsLetter: any = {
  title: {
    fr: (
      <>
        Suivez notre actualité en vous abonnant
        <br /> à la newsletter!
      </>
    ),
    ar: "تابع أخبارنا من خلال الاشتراك في نشرتنا الإخبارية!",
    en: (
      <>
        Stay updated by subscribing
        <br /> to our newsletter!
      </>
    ),
  },
};

export default newsLetter;

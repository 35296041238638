"use client";
import React, { useContext } from "react";
import Image from "next/image";
import ButtonLink from "../ui/button-link";
import PopcardCardFace from "@/assets/popcard-face.png";
import CompaniesSlider from "../ui/companies-slider";
import cardEffect from "@/assets/card-effect.svg";
import Container from "../ui/Container";
import { languageContext } from "@/contexts/language-context";
import hero from "@/translation/home/heroTranslation";
import { cn } from "@nextui-org/theme";
import LazySpline from "../lazy-spline";
import useStoreParamsCookies from "@/hooks/useStoreParamsCookies";
const Hero = ({ params }: { params: any }) => {
  // store  utm in cookies
  useStoreParamsCookies(params);
  const { lang } = useContext<any>(languageContext);
  return (
    <div className="max-w-screen  relative py-[3rem] sm:py-[5rem]">
      <Image
        src={cardEffect}
        alt={"card effect"}
        className={cn(
          "absolute top-0 left-1/5 opacity-70 hidden sm:block",
          lang === "ar" && "left-0"
        )}
      />

      <Image
        src={cardEffect}
        alt={"card effect"}
        className={cn(
          "absolute top-1/4 right-0 rotate-180 opacity-70 hidden sm:block",
          lang === "ar" && "right-0"
        )}
      />
      <Container className="">
        <div className="flex flex-col items-stretch gap-12">
          {/* HERO TEXT*/}
          <div className="w-full rounded-md flex flex-col items-center antialiased bg-grid-white/[0.02] relative overflow-hidden max-w-full">
            <div className="max-w-7xl mx-auto relative z-10  w-full pt-16 md:pt-0">
              <h1 className="text-2xl md:text-5xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50 pb-4">
                {hero.title[lang]}
              </h1>
              <p className="mt-4 text-md sm:text-md text-neutral-300 text-center mx-auto max-w-full md:max-w-[600px]">
                {hero.subtitle[lang]}
              </p>
            </div>
          </div>

          {/* HERO IMAGE*/}
          <div className="mt-2 flex flex-col items-center gap-8 w-full">
            <div className="hidden w-full sm:flex justify-center relative">
              <LazySpline />
            </div>
            <Image
              src={PopcardCardFace}
              alt="popcard face card"
              className="block sm:hidden max-w-[100%] drop-shadow-[0_0_0_rgba(255,255,255,0.5)]"
            />

            <ButtonLink
              href="/order"
              title={hero.commanderMaPopcard[lang]}
              className="sm:py-3"
            />
          </div>
        </div>
        <div className="w-full mt-16">
          <CompaniesSlider lang={lang} />
        </div>
      </Container>
    </div>
  );
};

export default Hero;

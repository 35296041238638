export const getCookies = () => {
  const cookieString = typeof document !== "undefined" ? document.cookie : "";
  return cookieString
    .split(";")
    .map((cookie) => cookie.trim())
    .reduce((acc: any, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key] = value;
      return acc;
    }, {});
};

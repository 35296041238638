// components/LazySpline.js
import Spline from "@splinetool/react-spline";

export default function LazySpline() {
  return (
    <Spline
      scene="https://prod.spline.design/d1p0T0RkVtqdn2IC/scene.splinecode"
      style={{
        width: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        justifyContent: "center",
        display: "flex",
        marginTop: -100,
      }}
      className="hidden sm:block"
    />
  );
}

import { useEffect } from "react";

function useStoreParamsCookies(params: any) {
  useEffect(() => {
    console.log("object to be stored", {
      utm_source: "",
      utm_content: "",
      utm_creative_format: "",
      meta_placement: "",
      utm_medium: "",
      utm_campaign: "",
      ...params,
    });
    if (params.utm_source && params.utm_source !== "organic")
      Object.keys({
        utm_source: "",
        utm_content: "",
        utm_creative_format: "",
        meta_placement: "",
        utm_medium: "",
        utm_campaign: "",
        ...params,
      }).forEach((key) => {
        const value = encodeURIComponent(params[key] || "") || "";

        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 10);

        document.cookie = `${key}=${value}; expires=${expires.toUTCString()}; path=/; SameSite=None; Secure`;
      });
  }, [params]);
}

export default useStoreParamsCookies;

"use client";
import React, { useContext, useState } from "react";
import SectionsHeader from "./ui/sections-header";
import Container from "./ui/Container";
import toast, { Toaster } from "react-hot-toast";
import { subscribeToNews } from "@/lib/api";
import { motion } from "framer-motion";
import { languageContext } from "@/contexts/language-context";
import newsLetter from "@/translation/newLetterTranslation";
const NewsLetter = () => {
  const { lang } = useContext<any>(languageContext);
  const [email, setEmail] = useState({
    value: "",
    error: "",
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!email.value) {
      return setEmail({ ...email, error: "requried" });
    }
    const data = await subscribeToNews(email.value);
    if (data) {
      toast.success("Merci de vous être abonné(e) à notre newsletter !");
      setEmail({
        value: "",
        error: "",
      });
    } else {
      toast.error("error de serveur");
    }
  };
  return (
    <div className="w-full py-[5rem] max-w-screen overflow-hidden">
      <Container className="flex flex-col gap-16 items-center">
        <SectionsHeader heading={newsLetter.title[lang]} />

        <motion.form
          initial={{ opacity: 0, y: 30 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.15,
            },
          }}
          dir="ltr"
          viewport={{ once: true }}
          className="border border-light-gray text-white  items-center rounded-full pr-1 py-1 w-full max-w-[95%] sm:max-w-[550px] relative hidden sm:flex"
          onSubmit={onSubmit}
        >
          <input
            type="email"
            value={email.value}
            onChange={(e) => setEmail({ error: "", value: e.target.value })}
            placeholder="example@mail.com"
            className="outline-none bg-transparent h-full px-4 sm:px-8 py-1 text-light-gray text-sm sm:text-md grow"
          />
          {email.error && (
            <small className="text-red-500 absolute -bottom-6 left-8">
              {lang === "fr"
                ? "Veuillez fournir une adresse e-mail valide."
                : lang === "en"
                ? "Please enter a valid email address."
                : "يرجى إدخال بريد إلكتروني صحيح."}
            </small>
          )}
          <button className="bg-white text-sm sm:text-md text-neutral-900 rounded-full px-3 sm:px-6 py-2 font-semibold hover:bg-white/80 transition-colors">
            {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "أرسل"}
          </button>
        </motion.form>

        <motion.form
          initial={{ opacity: 0, y: 30 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.15,
            },
          }}
          viewport={{ once: true }}
          className=" items-center rounded-full pr-1 py-1 w-full max-w-[95%] sm:max-w-[550px] relative flex flex-col gap-4 sm:hidden"
          onSubmit={onSubmit}
        >
          <input
            type="email"
            value={email.value}
            onChange={(e) => setEmail({ error: "", value: e.target.value })}
            placeholder="example@mail.com"
            className="outline-none bg-transparent h-full px-4 sm:px-8 py-2 rounded-full text-sm sm:text-md grow w-full border border-light-gray text-white"
          />
          {email.error && (
            <small className="text-red-500 absolute -bottom-6 left-8">
              {lang === "fr"
                ? "Veuillez fournir une adresse e-mail valide."
                : lang === "en"
                ? "Please enter a valid email address."
                : "يرجى إدخال بريد إلكتروني صحيح."}
            </small>
          )}
          <button className="bg-white text-sm sm:text-md text-neutral-900 rounded-full px-3 sm:px-6 py-2 font-semibold hover:bg-white/80 transition-colors">
            {lang === "fr" ? "Envoyer" : lang === "en" ? "Send" : "أرسل"}{" "}
          </button>
        </motion.form>
      </Container>
      <Toaster />
    </div>
  );
};

export default NewsLetter;

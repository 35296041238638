"use client";
import React, { useContext } from "react";
import SectionsHeader from "../ui/sections-header";
import { cn } from "@/lib/cn";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import collectPhone from "@/assets/collectPhone.png";
import partagePhone from "@/assets/partagePhone.png";
import Container from "../ui/Container";
import cardUsageTransaltion from "@/translation/home/cardUsage";
import { languageContext } from "@/contexts/language-context";

const CardUsage = () => {
  const [active, setActive] = React.useState("collect");
  const { lang } = useContext<any>(languageContext);
  return (
    <div className="w-full py-[3rem] md:py-[5rem]">
      <Container className="">
        <SectionsHeader heading={cardUsageTransaltion.title[lang]} />

        <div className="w-full grid grid-cols-4 sm:grid-rows-3 gap-8 align-items-center justify-items-center gap-y-16 pt-24">
          <GridItem
            className="card row-start-1 row-span-1 animate-wiggle col-start-1 col-span-1 hidden sm:flex -rotate-12"
            text={cardUsageTransaltion.contact[lang]}
            key={"grid1"}
          />

          <GridItem
            className="card row-start-2 row-span-1 animate-wiggle col-start-1 col-span-1 hidden sm:flex -rotate-8"
            text={cardUsageTransaltion.disponibilty[lang]}
            key={"grid2"}
          />

          <GridItem
            className="card row-start-3 row-span-1 animate-wiggle col-start-1 col-span-1 hidden sm:flex rotate-12"
            text={cardUsageTransaltion.photosAndVideos[lang]}
            key={"grid3"}
          />

          <GridItem
            className="card row-start-1 row-span-1 animate-wiggle col-start-4 col-span-1 hidden sm:flex rotate-12"
            text={cardUsageTransaltion.documents[lang]}
            key={"grid4"}
          />

          <GridItem
            className="card row-start-2 row-span-1 animate-wiggle col-start-4 col-span-1 hidden sm:flex rotate-5"
            text={cardUsageTransaltion.socials[lang]}
            key={"grid5"}
          />

          <GridItem
            className="card row-start-3 row-span-1 animate-wiggle col-start-4 col-span-1 hidden sm:flex -rotate-12"
            text={cardUsageTransaltion.productsAndServices[lang]}
            key={"grid6"}
          />
          <motion.div
            className="col-start-1 col-end-5 row-auto sm:col-start-2 sm:col-end-4 sm:row-start-1 sm:row-end-4 min-h-[550px] w-full h-full relative"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.15,
              },
            }}
            viewport={{ once: true }}
          >
            <AnimatePresence initial={false} key={lang + "animate"}>
              <ShareContactPhone active={active} />
              <ProfilePhone active={active} />
            </AnimatePresence>
          </motion.div>
        </div>

        <motion.div
          className="flex items-center p-2 border w-fit rounded-full mx-auto mt-8"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.15,
            },
          }}
          viewport={{ once: true }}
        >
          <button
            className={cn("px-2 py-1 sm:px-4 sm:py-2 relative")}
            onClick={() => setActive("collect")}
          >
            {active === "collect" && (
              <motion.div
                layoutId="back"
                className="bg-white absolute inset-0 rounded-full z-0"
              ></motion.div>
            )}
            <span
              className={cn(
                "relative z-10 ",
                active === "collect" ? "text-black" : "text-white"
              )}
            >
              {cardUsageTransaltion.partage[lang]}
            </span>
          </button>
          <button
            className={cn("px-2 py-1 sm:px-4 sm:py-2 relative")}
            onClick={() => setActive("partage")}
          >
            {active === "partage" && (
              <motion.div
                layoutId="back"
                className="bg-white absolute inset-0 rounded-full z-0"
              ></motion.div>
            )}
            <span
              className={cn(
                "relative z-10",
                active === "partage" ? "text-black" : "text-white"
              )}
            >
              {cardUsageTransaltion.collect[lang]}
            </span>
          </button>
        </motion.div>
      </Container>
    </div>
  );
};

const GridItem = ({
  text = "Votre fiche de contact",
  className,
}: {
  className: string;
  text?: string;
}) => {
  return (
    <motion.div
      className={cn(
        "h-24 bg-neutral-900 p-4 text-md sm:text-lg rounded-xl w-full flex items-center justify-center text-center scale-95",
        className
      )}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{
        opacity: 1,

        transition: {
          delay: 0.15,
        },
      }}
      viewport={{ once: true }}
    >
      <span className="flex">{text}</span>
    </motion.div>
  );
};

const activeState = {
  width: "60%",
  height: "100%",
  left: "60%",
  x: "-50%",
  zIndex: 10,
};

const unActiveState = {
  width: "55%",
  height: "93%",
  left: "35%",
  x: "-50%",
  zIndex: 0,
};
const ShareContactPhone = ({ active }: { active: string }) => {
  return (
    <motion.div
      layout
      animate={active === "collect" ? activeState : unActiveState}
      transition={{
        duration: 0.5,
      }}
      className={cn("absolute bottom-0 max-w-full")}
    >
      <Image
        src={collectPhone}
        alt="partger"
        className="w-full h-full object-contain"
      />
    </motion.div>
  );
};

const ProfilePhone = ({ active }: { active: string }) => {
  return (
    <motion.div
      layout
      animate={active === "partage" ? activeState : unActiveState}
      transition={{
        duration: 0.5,
      }}
      className={cn("absolute bottom-0 max-w-full")}
    >
      <Image
        src={partagePhone}
        alt="partger"
        className="w-full h-full object-contain"
      />
    </motion.div>
  );
};
export default CardUsage;

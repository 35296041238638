const cardUsage: any = {
  title: {
    ar: (
      <>
        في عالم الأعمال، هناك لحظات
        <br />
        نشارك فيها معلوماتنا وأخرى نجمع فيها المعلومات ...
      </>
    ),
    fr: (
      <>
        Dans le monde des affaires, il y a des moments
        <br />
        où l’on donne et d’autres où l’on reçoit ...
      </>
    ),
    en: (
      <>
        In the business world, there are moments
        <br />
        when we share information and others when we gather it...
      </>
    ),
  },
  documents: {
    fr: "Vos documents & liens",
    ar: "مستنداتك وروابطك",
    en: "Your documents & links",
  },
  socials: {
    fr: "Vos réseaux sociaux",
    ar: "شبكاتك الاجتماعية",
    en: "Your social networks",
  },
  productsAndServices: {
    fr: "Vos services & produits",
    ar: "خدماتك ومنتجاتك",
    en: "Your services & products",
  },
  contact: {
    fr: "Votre fiche contact",
    ar: "تفاصيل الاتصال بك",
    en: "Your contact information",
  },
  disponibilty: {
    fr: "Vos disponibilités",
    ar: "إمكانية توفرك",
    en: "Your availability",
  },
  photosAndVideos: {
    fr: "Vos photos & vidéos",
    ar: "صورك وفيديوهاتك",
    en: "Your photos & videos",
  },
  partage: { fr: "Partager", ar: "مشاركة", en: "Share" },
  collect: { fr: "Collecter", ar: "جمع", en: "Collect" },
};

export default cardUsage;

const lang: any = {
  ar: "اختر بطاقات أنيقة تعكس صورة شركتك",
  fr: (
    <>
      Optez pour des cartes élégantes <br />à l'image de votre entreprise
    </>
  ),
  en: (
    <>
      Choose elegant cards <br />
      that reflect your company's image
    </>
  ),
};

export default lang;

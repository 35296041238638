"use client";
import React, { useContext } from "react";
import Container from "../ui/Container";
import SectionsHeader from "../ui/sections-header";
import Image from "next/image";
import card1 from "@/assets/enterprise-face1.png";
import card2 from "@/assets/enterprise-face2.png";
import card3 from "@/assets/enterprise-face3.png";
import cardEnterpriseTranlation from "@/translation/home/cardEnterprise";
import { languageContext } from "@/contexts/language-context";

const CardEnterprise = () => {
  const { lang } = useContext<any>(languageContext);
  return (
    <div className="max-w-screen py-[5rem] hidden sm:block">
      <Container className="flex flex-col items-center gap-16">
        <SectionsHeader heading={cardEnterpriseTranlation[lang]} />

        <div className="w-[450px] flex justify-center relative group py-[3rem]">
          <Image
            loading="lazy"
            src={card2}
            alt="enterprise card"
            className="w-full h-auto top-1/2 -translate-y-1/2 absolute scale-50 object-cover left-0 group-hover:-left-[80%] group-hover:scale-[75%] group-hover:rotate-[-3deg] transition-all duration-[800ms]"
          />
          <Image
            loading="lazy"
            src={card1}
            alt="enterprise card"
            className="w-full object-cover relative z-10 group-hover:rotate-[-3deg] transition-all duration-[800ms]"
          />
          <Image
            loading="lazy"
            src={card3}
            alt="enterprise card"
            className="w-full h-auto absolute object-cover top-1/2 -translate-y-1/2 left-0 scale-50 group-hover:left-[80%] group-hover:scale-[75%] group-hover:rotate-[3deg] transition-all duration-[800ms]"
          />
        </div>
      </Container>
    </div>
  );
};

export default CardEnterprise;

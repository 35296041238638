const hero: any = {
  title: {
    fr: (
      <>
        La carte de visite digitale <br />
        N°1 au Maroc
      </>
    ),
    ar: "بطاقة الأعمال الرقمية رقم 1 في المغرب",
    en: (
      <>
        The No. 1 Digital Business <br />
        Card in Morocco
      </>
    ),
  },
  subtitle: {
    fr: "+ de 10.000 professionnels ont remplacé leurs anciennes cartes de visite en papier par une Popcard. Pourquoi pas vous?",
    ar: "+ من 10,000 محترف استبدلوا بطاقاتهم الورقية القديمة بـ Popcard. لماذا لا تفعلون ذلك أنتم؟",
    en: "Over 10,000 professionals have replaced their old paper business cards with Popcard. Why not you?",
  },
  commanderMaPopcard: {
    fr: "Commander ma Popcard",
    ar: "أطلب Popcard الخاصة بي",
    en: "Order my Popcard",
  },
  trusted: {
    fr: "Ils nous font confiance",
    ar: "موثوق به من قبل الشركات الكبرى",
    en: "Trusted by major companies",
  },
};

export default hero;

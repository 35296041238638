"use client";
import React, { useContext } from "react";
/* import Image from "next/image"; */
import SectionsHeader from "../ui/sections-header";
/* import PopcardDemo from "@/assets/popcard-face.webp"; */
import Container from "../ui/Container";
import cardDemoTanslation from "@/translation/home/cardDemo";
import { languageContext } from "@/contexts/language-context";
const CardDemo = () => {
  const { lang } = useContext<any>(languageContext);
  return (
    <div className="max-w-screen py-[5rem]">
      <Container className="flex flex-col gap-16">
        <SectionsHeader
          heading={cardDemoTanslation.title[lang]}
          subHeading={cardDemoTanslation.subtitle[lang]}
        />

        <div className="w-full flex justify-center">
          <iframe
            loading="lazy"
            className="responsive-iframe-container"
            src="https://www.youtube.com/embed/RrP6R8_nEKc?si=VHgjX6VGl6T3miQs"
            title="Popcard Trailer video"
          ></iframe>
        </div>
      </Container>
    </div>
  );
};

export default CardDemo;
